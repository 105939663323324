import React, { useContext } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";

const CreateBtn = (props) => {
    const { setOpen, page } = props;
    const { handleOpenSnackbar, access } = useContext(CommonApiDataContext);

    const openModel = async () => {
        const isAdmin = access === "admin";
        const isSchoolPage = access === "inmobiusUser" && page === "Schools";
        if (isAdmin || isSchoolPage) {
            handleOpenSnackbar(true, "Access denied!", "error");
        } else {
            setOpen && setOpen(true);
        }
    };

    return (
        access !== "other" && (
            <Button onClick={openModel} variant="contained" startIcon={<AddIcon />}>
                Create
            </Button>
        )
    );
};

export default CreateBtn;
