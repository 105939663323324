import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";
import { UpdateProgram, getPrograms } from "../../../utils";
import Table from "../../table/Table";
import ProgramsWrapper from "./ProgramsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { moduleConfig } from "../../../config";
import { GetSearchParams } from "../../../utils/reusedMethods";

const Programs = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({
        searchText: "",
        searchBy: moduleConfig.programs?.searchableItems[0]?.value,
    });

    const { products, grades, exams, handleOpenSnackbar, setKeys } = useContext(CommonApiDataContext);
    const history = useHistory();
    const searchParams = GetSearchParams();

    const closeModal = () => setOpen(false);

    const accessToken = localStorage.getItem("accessToken");

    // Check if the user is authenticated on component mount
    useEffect(() => {
        document.title = "Gateway Admin | Programs";
        if (!isAuthenticated()) {
            // User is not authenticated, redirect to the login page
            history.push("/login");
        }
    }, [history]);

    useEffect(() => {
        const product_id = searchParams.get("product_id");
        product_id ? setProductId(product_id) : setProductId((products && products[0]?.value) || null);
    }, [products, searchParams]);

    useEffect(() => {
        setKeys({ prods: "product_ids", grads: "grade_ids", exm: "exam_type" });
    }, [setKeys]);

    // get Program Data whenever Product Id changes
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const programData = await getPrograms(accessToken, currPage, limit, productId);

                const updatedData = programData?.programs?.map((program) => ReplaceIdsWithLabels(program));
                setData(updatedData);
                setCurrPage(programData?.currentPage);
                setTotalPages(programData?.totalPages);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
            }
        };
        productId && fetchData();
    }, [currPage, limit, productId]);

    const ReplaceIdsWithLabels = (item) => {
        const UP = item?.product_ids?.map((prodId) => {
            const product = products?.find((prod) => prod?.value === prodId);
            return product ? product?.label : prodId;
        });

        const UG = item?.grade_ids?.map((gradeId) => {
            const grade = grades?.find((itemData) => itemData?.value === gradeId);

            return grade ? grade?.label : gradeId;
        });

        const UE = exams?.filter((e) => e.value === Number(item?.exam_type))[0]?.label;

        return { ...item, product_ids: UP, grade_ids: UG, exam_type: UE };
    };

    const onUpdateProgram = async (formData) => {
        try {
            const res = await UpdateProgram(accessToken, formData);
            const UUD = data.map((e) => {
                if (e.program_id === formData.program_id) {
                    return ReplaceIdsWithLabels(res);
                }
                return e;
            });
            setData(UUD);
            handleOpenSnackbar(true, "Program has been successfully Updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const handleToggle = async (item) => {
        let chngIsAtvTo = null;
        const updatedList = data.map((e) => {
            if (e?.tenant_id === item?.tenant_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });

        // payload for activate or deactivate the Programs
        const updateStatusPayload = {
            program_id: item?.program_id,
            is_active: chngIsAtvTo,
        };

        await onUpdateProgram(updateStatusPayload);
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Programs</h1>
                <CreateBtn setOpen={setOpen} />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <ProgramsWrapper ModalTitle="Create Program" closeModal={closeModal} dta={data} setData={setData} />{" "}
                </ModalDialog>
            </div>
            <Table
                navigationId={7}
                data={data}
                pageTitle="Programs"
                loading={loading}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                onToggle={handleToggle}
                updateItem={onUpdateProgram}
                EditForm={ProgramsWrapper}
                setProductIds={setProductId}
                setData={setData}
                setSearch={setSearch}
                search={search}
            />
        </section>
    );
};

export default Programs;
