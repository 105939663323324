import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../table/Table";
import { GetAllSchools, SearchSchools, UpdateSchool } from "../../../utils";
import { isAuthenticated } from "../../../utils/auth";
import SchoolsWrapper from "./SchoolsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { CONFIG_VARS } from "../../../config/globleConfig";
import { moduleConfig } from "../../../config";
import { GetSearchParams } from "../../../utils/reusedMethods";

const Schools = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [productId, setProductId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({ searchText: "", searchBy: moduleConfig.schools?.searchableItems[0]?.value });
    const closeModal = () => setOpen(false);
    const history = useHistory();
    const searchParams = GetSearchParams();
    const accessToken = localStorage.getItem("accessToken");

    const { products, tenants, handleOpenSnackbar, setKeys, access } = useContext(CommonApiDataContext);

    // Check if the user is authenticated on component mount
    useEffect(() => {
        document.title = "Gateway Admin | Schools";
        if (!isAuthenticated()) {
            // User is not authenticated, redirect to the login page
            history.push("/login");
        }
    }, [history]);

    // useEffect(() => {
    //     setProductId((products && products[0]?.value) || null);
    // }, [products]);

    /*@TODO: need remove in final stage */
    useEffect(() => {
        /*@TODO: need remove in final stage */
        const getProds = () => {
            if (access && access.includes("inmobius")) {
                return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
            } else {
                return products.filter((p) => CONFIG_VARS.schoolsProds.includes(p.value));
            }
        };

        const inmobiusProd = getProds();
        const product_id = searchParams.get("product_id");
        product_id ? setProductId(product_id) : setProductId((inmobiusProd && inmobiusProd[0]?.value) || "");
    }, [access, products, searchParams]);

    useEffect(() => {
        setKeys({ prod: "product_id", tnt: "tenant_id" });
    }, [setKeys]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const schoolsData = await GetAllSchools(accessToken, currPage, limit, productId);
                const updatedData = schoolsData?.schools?.map((item) => ReplaceIdsWithLabels(item));
                // const updatedData = await Promise.all(updatedDataPromises);

                setData(updatedData);
                setCurrPage(schoolsData?.currentPage);
                setTotalPages(schoolsData?.totalPages);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail || error.message, "error");
                setLoading(false);
            }
        };
        productId && fetchData();
    }, [currPage, limit, productId]);

    // const getStateName = async (stateId) => {
    //     const states = await getStates("IN");
    //     const stateName = states.find((state) => state?.state_id === stateId)?.state_name;

    //     return stateName;
    // };

    // const getCityName = async (stateCode, citycode) => {
    //     const cities = stateCode && (await getCities("IN", stateCode));
    //     const cityName = cities?.find((city) => city?.city_id === citycode)?.city_name;
    //     return cityName;
    // };

    const ReplaceIdsWithLabels = (item) => {
        // const stateName = await getStateName(item?.state);
        // const cityName = await getCityName(item?.state, item?.city);

        const UP = products?.filter((e) => e?.value === item?.product_id)[0]?.label;
        const UT = tenants?.filter((e) => e?.value === item?.tenant_id)[0]?.label;

        return {
            ...item,
            product_id: UP,
            tenant_id: UT,
            //  state: stateName, city: cityName
        };
    };

    const onUpdateSchool = async (formData) => {
        try {
            const res = await UpdateSchool(accessToken, formData);
            const UUD = data.map((e) => {
                if (e?.school_id === res?.school_id) {
                    return ReplaceIdsWithLabels(res);
                }
                return e;
            });
            setData(UUD);
            handleOpenSnackbar(true, "School has been successfully updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
        }
    };

    const handleToggle = async (item) => {
        // payload for activate or deactivate the Schools
        const updateStatusPayload = {
            school_id: item?.school_id,
            is_active: !item.is_active,
            product_id: products?.filter((e) => e?.label === item?.product_id)[0]?.value,
        };

        await onUpdateSchool(updateStatusPayload);
    };

    const onSearchSchools = async () => {
        const searchObj = {
            search_text: search.searchText,
            wild_search: true,
            in_phone: search.searchBy.includes("phone"),
            in_contact_name: search.searchBy.includes("contact_name"),
            in_school_name: search.searchBy.includes("school_name"),
            in_email: search.searchBy.includes("email"),
        };

        try {
            setLoading(true);
            const searchedData = await SearchSchools(accessToken, searchObj, currPage, limit, productId);
            const updatedData = searchedData?.schools?.map((school) => ReplaceIdsWithLabels(school));
            setData(updatedData);
            setCurrPage(searchedData?.currentPage);
            setTotalPages(searchedData?.totalPages);
            setLoading(false);
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
            setLoading(false);
        }
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Schools</h1>
                <CreateBtn setOpen={setOpen} page="Schools" />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <SchoolsWrapper ModalTitle="Create School" closeModal={closeModal} dta={data} setData={setData} />
                </ModalDialog>
            </div>
            <Table
                navigationId={1}
                pageTitle="Schools"
                data={data}
                loading={loading}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                onToggle={handleToggle}
                updateItem={onUpdateSchool}
                EditForm={SchoolsWrapper}
                setProductIds={setProductId}
                setData={setData}
                setSearch={setSearch}
                search={search}
                onSearch={onSearchSchools}
            />
        </section>
    );
};

export default Schools;
