export const SnackbarAnchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

export const itemsPerPageOptions = [
    { label: "5 per page", value: 5 },
    { label: "10 per page", value: 10 },
    { label: "15 per page", value: 15 },
    { label: "20 per page", value: 20 },
    { label: "25 per page", value: 25 },
];

export const radioGroupDirectionStylesForRoles = {
    display: "Flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20%",
    marginBottom: "10px",
};
