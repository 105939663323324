import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";
import { UpdateExam, getExams } from "../../../utils";
import Table from "../../table/Table";
import ExamsWrapper from "./ExamsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { moduleConfig } from "../../../config";

const Exams = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({ searchText: "", searchBy: moduleConfig.exams?.searchableItems[0]?.value });

    const closeModal = () => setOpen(false);
    const history = useHistory();
    const accessToken = localStorage.getItem("accessToken");
    const { handleOpenSnackbar } = useContext(CommonApiDataContext);

    // Check if the user is authenticated on component mount
    useEffect(() => {
        document.title = "Gateway Admin | Exam Types";
        if (!isAuthenticated()) {
            // User is not authenticated, redirect to the login page
            history.push("/login");
        }
    }, [history]);

    useEffect(() => {
        const getAllExams = async () => {
            setLoading(true);
            try {
                const data = await getExams(accessToken, currPage, limit);
                setData(data?.exams?.reverse());
                setCurrPage(data?.currentPage);
                setTotalPages(data?.totalPages);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
            }
        };
        getAllExams();
    }, [currPage, limit]);

    const onUpdateExam = async (formData) => {
        try {
            const res = await UpdateExam(accessToken, formData);
            const UUD = data.map((e) => {
                if (e.exam_id === formData.exam_id) {
                    return res;
                }
                return e;
            });
            setData(UUD);
            handleOpenSnackbar(true, "Exam has been successfully Updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
        }
    };

    const handleToggle = async (item) => {
        let chngIsAtvTo = null;

        const updatedList = data.map((e) => {
            if (e?.exam_id === item?.exam_id) {
                const isActive = item?.active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    active: !isActive,
                };
            }
            return e;
        });

        // payload for activate or deactivate the Tenants
        const updateStatusPayload = {
            exam_id: item?.exam_id,
            active: chngIsAtvTo,
        };

        await onUpdateExam(updateStatusPayload);
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Exams Types</h1>
                <CreateBtn setOpen={setOpen} />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <ExamsWrapper ModalTitle="Create Exam" closeModal={closeModal} dta={data} setData={setData} />
                </ModalDialog>
            </div>
            <Table
                navigationId={5}
                pageTitle="Exams"
                loading={loading}
                data={data}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                onToggle={handleToggle}
                updateItem={onUpdateExam}
                EditForm={ExamsWrapper}
                setData={setData}
                setSearch={setSearch}
                search={search}
            />
        </section>
    );
};

export default Exams;
