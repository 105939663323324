import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getAllJobDetails } from "../../../utils";
import { itemsPerPageOptions } from "../../../constants";
import BulkUploadBtn from "../../../components/buttons/BulkUploadBtn";

const UploadHistory = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);

    useEffect(() => {
        setLoading(true);
        const getHistory = async () => {
            const res = await getAllJobDetails(page, limit, localStorage.getItem("productId"));
            setData(res?.logs);
            setPage(res?.currentPage);
            setTotalPages(res?.totalPages);
            setLoading(false);
        };
        getHistory();
    }, [page, limit]);

    const limitChangeHandler = (e) => {
        setPage(1);
        setLimit(parseInt(e.target.value));
    };

    const pageChangeHandler = (event, value) => {
        setPage(value);
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Users / bulk upload / history</h1>
                <BulkUploadBtn text="Upload New" link="/users-bulk-upload" />
            </div>
            <div className="table-card">
                <Link to="/users-bulk-upload">
                    <KeyboardBackspaceIcon /> Back
                </Link>
                {loading ? (
                    <div className="loader-container">
                        <ThreeDots type="Oval" color="#4154f1" height={80} width={80} />
                    </div>
                ) : (
                    <table style={{ marginTop: "30px" }}>
                        <thead>
                            <tr>
                                <th>Job ID</th>
                                <th>Job Type</th>
                                <th>Uploaded By</th>
                                <th>Original Records</th>
                                <th>Inserted</th>
                                <th>Skipped</th>
                                <th>Created At</th>
                                <th>Status</th>
                                <th>More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((record, index) => (
                                <tr key={index}>
                                    <td style={{ maxWidth: "150px" }}>{record.job_id || "--"}</td>
                                    <td>{record.job_type || "--"}</td>
                                    <td>{record.user_crn_id || "---"}</td>
                                    <td>{record.original_records || "--"}</td>
                                    <td>{record.inserted_records || "--"}</td>
                                    <td>{record.skipped_records || "--"}</td>
                                    <td>{record.started_at || "--"}</td>
                                    <td
                                        style={{
                                            color:
                                                record.status === "finished"
                                                    ? "green"
                                                    : record.status === "in-progress"
                                                    ? "orange"
                                                    : "red",
                                        }}>
                                        {record.status || "--"}
                                    </td>
                                    <Link to={`/bulk-upload-history/${record.job_id}`}>
                                        <td>
                                            <VisibilityRoundedIcon
                                                color="primary"
                                                className="table-control-icons"
                                                titleAccess="View Item"
                                            />
                                        </td>
                                    </Link>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {data.length !== 0 && (
                    <div className="table-pagn-itemspp-box">
                        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                            <InputLabel id="demo-select-small-label">Items per Page</InputLabel>
                            <Select
                                MenuProps={{ PaperProps: { sx: { maxHeight: 120, width: 100 } } }}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={limit}
                                label="Items per Page"
                                onChange={(e) => limitChangeHandler(e)}>
                                {itemsPerPageOptions.map((option) => (
                                    <MenuItem key={option?.value} value={option?.value}>
                                        {option?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Pagination
                            variant="outlined"
                            color="primary"
                            count={totalPages}
                            page={page}
                            onChange={pageChangeHandler}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default UploadHistory;
