import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

// Importing CSS files
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/css/style.css";

// Importing components and constants
import { ROUTES } from "../constants/routes";
import LoginForm from "../components/LoginForm";
import ResetPassword from "../components/ResetPassword";
import {
    TenantDashboard,
    GradesDashboard,
    SchoolDashboard,
    UsersDashboard,
    ProductDashboard,
    ExamsDashboard,
    AgentsDashboard,
    ProgramsDashboard,
    RolesDashboard,
    MDashboard,
    UserBulkUploadDashboard,
    UsersUploadHistoryDashboard,
    SingleJobDetailDashboard,
    // Footer,
} from "../components";
import SessionTimeoutWrapper from "../utils/SessionTimeout";

const AuthenticatedRoutes = () => {
    // const location = useLocation();

    // const shouldShowFooter = () => {
    //     const nonFooterRoutes = [ROUTES.LOGIN, ROUTES.RESET_PASSWORD];
    //     return !nonFooterRoutes.includes(location.pathname);
    // };

    return (
        <>
            <Switch>
                {/* Route for the main dashboard */}
                {/* <Route path={ROUTES.CARDS} component={Dashboard} /> */}

                {/* Routes for various dashboard pages */}
                <Route path={ROUTES.TENANTS} component={TenantDashboard} />
                <Route path={ROUTES.GRADES} component={GradesDashboard} />
                <Route path={ROUTES.SCHOOLS} component={SchoolDashboard} />
                <Route path={ROUTES.USERS} component={UsersDashboard} />
                <Route path={ROUTES.PRODUCTS} component={ProductDashboard} />
                <Route path={ROUTES.ROLES} component={RolesDashboard} />
                <Route path={ROUTES.EXAMS} component={ExamsDashboard} />
                <Route path={ROUTES.AGENTS} component={AgentsDashboard} />
                <Route path={ROUTES.PROGRAMS} component={ProgramsDashboard} />
                <Route path={ROUTES.USERBULKUPLOAD} component={UserBulkUploadDashboard} />
                <Route path={ROUTES.USERBULKUPLOADHISORY} component={UsersUploadHistoryDashboard} />
                <Route path={`/bulk-upload-history/:id`} component={SingleJobDetailDashboard} />

                {/* Add more routes for authenticated pages */}

                {/* Redirect to default authenticated page */}
                <Redirect to={ROUTES.CARDS} />
            </Switch>
            {/* {shouldShowFooter() && <Footer />} */}
        </>
    );
};

const MainRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path={ROUTES.LOGIN} component={LoginForm} />
                <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
                <Route path={ROUTES.CARDS} component={MDashboard} />
                {/* Add other routes as needed */}
                <Route>
                    <SessionTimeoutWrapper>
                        <AuthenticatedRoutes />
                    </SessionTimeoutWrapper>
                </Route>
                <Redirect to={ROUTES.LOGIN} />
            </Switch>
        </Router>
    );
};

export default MainRouter;
