import * as React from "react";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SnackbarAnchorOrigin } from "../../constants";

const DynamicSnackBar = (data) => {
    const [snackbarData, setSnackData] = useState({ open: false, msg: "", type: "" });
    const { vertical, horizontal } = SnackbarAnchorOrigin;

    useEffect(() => {
        setSnackData({ open: data.open, msg: data.msg, type: data.type });
    }, [data]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackData({ open: false });
    };

    return (
        <Snackbar
            open={snackbarData.open}
            autoHideDuration={4000}
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}
            sx={{ margin: "0px 0px 35px 0px" }}
            onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarData.type} sx={{ width: "100%" }}>
                {snackbarData.msg}
            </Alert>
        </Snackbar>
    );
};

export default DynamicSnackBar;
