// SessionTimeoutWrapper.js
import React, { useEffect } from "react";

const SessionTimeoutWrapper = ({ children }) => {
    useEffect(() => {
        const sessionStart = localStorage.getItem("sessionStart");

        const logout = () => {
            // Perform logout actions, e.g., clear user data, redirect to login page
            // Here, you might use React Router to redirect to the login page
            window.location.href = "/login"; // Change the URL to your login page
            localStorage.removeItem("sessionStart");
        };

        const checkSessionTimeout = () => {
            const currentTime = new Date().getTime();
            const sessionDuration = 24 * 60 * 60 * 1000; // 1 day in milliseconds

            if (currentTime - parseInt(sessionStart, 10) > sessionDuration) {
                logout();
            }
        };

        const initializeSession = () => {
            const currentTime = new Date().getTime();
            if (!sessionStart) {
                localStorage.setItem("sessionStart", currentTime.toString());
            } else {
                checkSessionTimeout();
            }
        };

        initializeSession();

        const sessionInterval = setInterval(checkSessionTimeout, 60000); // Check every minute

        return () => clearInterval(sessionInterval);
    }, []);

    return <>{children}</>;
};

export default SessionTimeoutWrapper;
