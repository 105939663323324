import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Import dayjs library

export const BasicDatePicker = (props) => {
    const { handleChange, name, value } = props;

    const handleDateChange = (newValue) => {
        // Format the new value using dayjs to 'D/M/YYYY' format
        const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
        const e = { target: { name, value: formattedDate } };
        // Callback to parent component with the selected date value
        handleChange(e);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
                <DatePicker
                    name={name}
                    value={value} // Pass the selectedDate as the value
                    onChange={(newValue) => handleDateChange(newValue)}
                    sx={{ width: "100%" }}
                    label="Date of birth"
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};
