export const ROUTES = Object.freeze({
    HOME: "/",
    LOGIN: "/login",
    PAGE_NOT_FOUND: "/page-not-found",
    RESET_PASSWORD: "/reset-password",
    CARDS: "/dashboard",
    TENANTS: "/tenants",
    SCHOOLS: "/schools",
    STUDENTS: "/students",
    USERS: "/users",
    USERBULKUPLOAD: "/users-bulk-upload",
    USERBULKUPLOADHISORY: "/users-bulk-upload-history",
    GRADES: "/grades",
    PRODUCTS: "/products",
    ROLES: "/roles",
    EXAMS: "/exams",
    AGENTS: "/agents",
    PROGRAMS: "/programs",
});
