import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

const CustomButton = (props) => {
    const { text, variant, type, action, startIcon, icon, actionVal, size, color, style } = props;
    return text ? (
        <Button
            sx={style}
            startIcon={startIcon}
            variant={variant}
            type={type}
            onClick={action}
            size={size}
            color={color}>
            {text}
        </Button>
    ) : (
        <IconButton onClick={() => action(actionVal)} color={color}>
            {icon}
        </IconButton>
    );
};

export default CustomButton;
