import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";
import { getAgents, UpdateAgent, ActivateAgents, DeactivateAgents, searchAgents } from "../../../utils";
import Table from "../../table/Table";
import AgentsWrapper from "./AgentsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { CONFIG_VARS } from "../../../config/globleConfig";
import { moduleConfig } from "../../../config";
import { GetSearchParams } from "../../../utils/reusedMethods";

const Agents = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({ searchText: "", searchBy: moduleConfig.agents?.searchableItems[0]?.value });

    const history = useHistory();
    const searchParams = GetSearchParams();
    const { products, roles, handleOpenSnackbar, setKeys, access } = useContext(CommonApiDataContext);
    const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        document.title = "Gateway Admin | Agents";
        if (!isAuthenticated()) {
            history.push("/login");
        }
    }, [history]);

    // useEffect(() => {
    //     setProductId((products && products[0]?.value) || null);
    // }, [products]);

    /*@TODO: need remove in final stage */
    useEffect(() => {
        /*@TODO: need remove in final stage */
        const getProds = () => {
            if (access && access.includes("inmobius")) {
                return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
            } else if (access && access === "learnSuperAdmin") {
                return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
            } else if (access && access === "ILSchoolsSuperAdmin") {
                return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
            } else {
                return products;
            }
        };
        const inmobiusProd = getProds();
        const product_id = searchParams.get("product_id");

        product_id ? setProductId(product_id) : setProductId((inmobiusProd && inmobiusProd[0]?.value) || "");
    }, [access, products, searchParams]);

    useEffect(() => {
        setKeys({ rols: "roles" });
    }, [setKeys]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const agentsData = await getAgents(accessToken, currPage, limit, productId);

                const updatedData = agentsData?.agents?.map((user) => ReplaceRolesWithLabels(user));
                setCurrPage(agentsData?.currentPage);
                setTotalPages(agentsData?.totalPages);
                setData(updatedData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
            }
        };
        productId && (search.searchText === "" ? fetchData() : onSearchAgents());
    }, [currPage, limit, productId]);

    const ReplaceRolesWithLabels = (user) => {
        const UR = user?.roles?.map((roleId) => {
            const role = roles?.find((roleData) => roleData?.value === roleId);
            return role ? role?.label : roleId;
        });

        return { ...user, roles: UR };
    };

    const onUpdateAgent = async (formData) => {
        try {
            const res = await UpdateAgent(accessToken, formData);
            const UUD = data.map((e) => {
                if (e?.admission_number === res?.admission_number) {
                    return ReplaceRolesWithLabels(res);
                }
                return e;
            });
            setData(UUD);

            // console.log(res);
            handleOpenSnackbar(true, "updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error?.message, "error");
        }
    };

    const OnActivateAgent = async (payload) => {
        try {
            await ActivateAgents(accessToken, payload);
            handleOpenSnackbar(true, "Agent has been successfully activated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const OnDeactivateAgent = async (payload) => {
        try {
            await DeactivateAgents(accessToken, payload);
            handleOpenSnackbar(true, "Agent has been successfully deactivated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const handleToggle = (item) => {
        let chngIsAtvTo = null;
        const updatedList = data.map((e) => {
            if (e?.uam_id === item?.uam_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });
        setData(updatedList);
        const ids = {
            user_ids: [item?.uam_id],
        };

        chngIsAtvTo === false ? OnDeactivateAgent(ids) : OnActivateAgent(ids);
    };

    const onSearchAgents = async () => {
        const searchObj = {
            admission_number: search.searchBy === "admission_id" ? search.searchText : "",
            phone: search.searchBy === "phone" ? search.searchText : "",
            isd_code: "+91",
        };

        try {
            const searchedData = await searchAgents(accessToken, searchObj);
            const updatedData = searchedData?.agents?.map((user) => ReplaceRolesWithLabels(user));
            setData(updatedData);
            setCurrPage(1);
            setTotalPages(1);
            setLoading(false);
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
            setLoading(false);
        }
    };

    const closeModal = () => setOpen(false);

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Agents</h1>
                <CreateBtn setOpen={setOpen} />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <AgentsWrapper ModalTitle="Create Agent" closeModal={closeModal} dta={data} setData={setData} />
                </ModalDialog>
            </div>
            <Table
                navigationId={8}
                pageTitle="Agents"
                data={data}
                loading={loading}
                onToggle={handleToggle}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                updateItem={onUpdateAgent}
                EditForm={AgentsWrapper}
                setProductIds={setProductId}
                setData={setData}
                setSearch={setSearch}
                search={search}
                onSearch={onSearchAgents}
            />
        </section>
    );
};

export default Agents;
