import { useContext, useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
// import { getExams, getGrades, getProducts } from "../../../utils";
import { TableConfig } from "../../table/TableConfig";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";

const ProgramsWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.programs.fields);
    const [formData, setFormData] = useState({ ...data });
    const [loader, setLoader] = useState(false);

    const { products, grades, exams } = useContext(CommonApiDataContext);

    useEffect(() => {
        // const accessToken = localStorage.getItem("accessToken");
        const getAllGradesExamsAndProducts = () => {
            setLoader(true);
            try {
                // const grades = await getGrades();
                // const products = await getProducts(1, 100);
                // const exams = await getExams(accessToken, 1, 100);

                // const updatedGrades = grades?.map((e) => ({ label: e.grade_name, value: e.grade_id }));
                // const updatedProducts = products?.products?.map((e) => ({
                //     label: e.product_name,
                //     value: e.product_id,
                // }));
                // const updatedExams = exams?.map((e) => ({ label: e.name, value: e.exam_id }));

                const filteredProducts = products?.filter((product) => data?.product_ids?.includes(product?.label));
                const filteredGrades = grades?.filter((grade) => data?.grade_ids?.includes(grade?.label));
                const filteredExams = exams?.filter((exam) => exam?.label === data?.exam_type)[0];

                data &&
                    setFormData((prev) => ({
                        ...prev,
                        product_ids: filteredProducts,
                        grade_ids: filteredGrades,
                        exam_type: filteredExams
                    }));

                const updatedFields = fields.map((e) => {
                    if (e.options && e.name === "product_ids") {
                        return { ...e, options: products?.filter((e) => e.active) };
                    } else if (e.options && e.name === "grade_ids") {
                        return { ...e, options: grades?.filter((e) => e.active) };
                    } else if (e.options && e.name === "exam_type") {
                        return { ...e, options: exams?.filter((e) => e.active) };
                    }
                    return e;
                });

                const reqFields = data ? updatedFields : updatedFields.filter((e) => e.showInCreate && e);

                setFields(reqFields);
                setLoader(false);
            } catch (error) {
                console.error(error);
                setLoader(false);
            }
        };

        getAllGradesExamsAndProducts();
    }, []);

    clearInterval();

    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            loader={loader}
            actions={moduleConfig?.programs?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[7].items[0].id}
        />
    );
};

export default ProgramsWrapper;
