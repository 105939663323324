import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import BulkUpload from "../../../components/bulkUpload/BulkUpload";
import BulkUploadBtn from "../../../components/buttons/BulkUploadBtn";

const UsersBulkUpload = () => {
    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Users / bulk upload</h1>

                <BulkUploadBtn text="Upload history" link="/users-bulk-upload-history" />
            </div>
            <div className="table-card">
                <Link to="/users">
                    <KeyboardBackspaceIcon /> Back
                </Link>
                <BulkUpload />
            </div>
        </section>
    );
};

export default UsersBulkUpload;
