import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getGradesDataByProducts, updateGrades } from "../../../utils";
import { isAuthenticated } from "../../../utils/auth";
import Table from "../../table/Table";
import GradesWrapper from "./GradesWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { moduleConfig } from "../../../config";
import { GetSearchParams } from "../../../utils/reusedMethods";

const Grades = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({ searchText: "", searchBy: moduleConfig.grades?.searchableItems[0]?.value });

    const { products, handleOpenSnackbar, setKeys } = useContext(CommonApiDataContext);

    const history = useHistory();
    const searchParams = GetSearchParams();
    const accessToken = localStorage.getItem("accessToken");

    // Check if the user is authenticated on component mount
    useEffect(() => {
        document.title = "Gateway Admin | Grades";
        if (!isAuthenticated()) {
            // User is not authenticated, redirect to the login page
            history.push("/login");
        }
    }, [history]);

    useEffect(() => {
        const product_id = searchParams.get("product_id");
        product_id ? setProductId(product_id) : setProductId((products && products[0]?.value) || null);
    }, [products, searchParams]);

    useEffect(() => {
        setKeys({ prod: "products" });
    }, [setKeys]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const gradesData = await getGradesDataByProducts(accessToken, currPage, limit, productId);

                const updatedData = gradesData?.grades.map((item) => ReplaceIdsWithLabels(item));
                setData(updatedData?.reverse());
                setCurrPage(gradesData?.currentPage);
                setTotalPages(gradesData?.totalPages);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
            }
        };
        productId && fetchData();
    }, [currPage, limit, productId]);

    const closeModal = () => setOpen(false);

    const ReplaceIdsWithLabels = (item) => {
        const UP = item?.products?.map((prodId) => {
            const product = products?.find((prod) => prod?.value === prodId);
            return product ? product?.label : prodId;
        });

        return { ...item, products: UP };
    };

    const EditGrades = async (updatedData) => {
        try {
            const res = await updateGrades(accessToken, updatedData);
            const UUD = data.map((e) => {
                if (e?.grade_id === res?.grade_id) {
                    return ReplaceIdsWithLabels(res);
                }
                return e;
            });
            setData(UUD);
            handleOpenSnackbar(true, "Grade has been successfully updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
        }
    };

    const handleToggle = async (item) => {
        let chngIsAtvTo = null;

        const updatedList = data.map((e) => {
            if (e?.grade_id === item?.grade_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });

        // payload for activate or deactivate the Products
        const updateStatusPayload = {
            grade_id: item?.grade_id,
            grade_name: item?.grade_name,
            is_active: chngIsAtvTo,
        };

        await EditGrades(updateStatusPayload);
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Grades</h1>
                <CreateBtn setOpen={setOpen} />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <GradesWrapper ModalTitle="Create Grade" closeModal={closeModal} dta={data} setData={setData} />
                </ModalDialog>
            </div>
            <Table
                navigationId={2}
                pageTitle="Grades"
                data={data}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                loading={loading}
                onToggle={handleToggle}
                updateItem={EditGrades}
                EditForm={GradesWrapper}
                setProductIds={setProductId}
                setData={setData}
                setSearch={setSearch}
                search={search}
            />
        </section>
    );
};

export default Grades;
